import $ from 'jquery';
window.$ = $;

Rails.start();

import MicroModal from 'micromodal';

import flatpickr from "flatpickr"
import { Japanese } from "flatpickr/dist/l10n/ja.js"
flatpickr.localize(Japanese);

import 'select2';
import 'select2/dist/js/i18n/ja';

import Cropper from 'cropperjs';

$(function() {
  $(".js-flatpickr").each(function(){
    flatpickr(this, { allowInput: true })
  })

  $(".js-select2").select2()
});

// select2

function agentFormat(res) {
  if (res.loading) {
    return res.text;
  }

  var $container = $(`
    <p class="text-xs text-gray-500">ID: ${res.id}</p>
    <p class="font-bold">${res.name}</p>
    <p class="text-xs text-gray-500">グレード: ${res.grade} / 患者数: ${res.patient_count}</p>
  `);

  return $container;
}

$(document).ready(function () {
  $(".js-agent-select2").select2({
    ajax: {
      url: "/api/agents",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          q: params.term,
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        const results = data.results.map(result => {
          return {
            id: result.id,
            text: result.name,
            ...result,
          };
        });

        return {
          results: results,
          pagination: {
            more: data.more
          }
        };
      },
    },
    allowClear: true,
    dropdownAutoWidth: true,
    language: "ja",
    minimumInputLength: 1,
    placeholder: "代理店を「ID」か「名前」で検索",
    templateResult: agentFormat,
  })
})

function medicalRecordFormat(res) {
  if (res.loading) {
    return res.text;
  }

  var $container = $(`
    <p class="font-bold">${res.patient_name}</p>
    <p class="text-xs text-gray-500">カルテ番号: ${res.medical_record_number} / 代理店: ${res.agent_name} / 初回来院日: ${res.first_visit_on || "---"} / 細胞採取日: ${res.sample_on || "---"} / 注入日: ${res.inject_on || "---"}</p>
  `);

  return $container;
}

$(document).ready(function () {
  $(".js-medical-record-select2").select2({
    ajax: {
      url: "/api/medical-records",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          q: params.term,
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        const results = data.results.map(result => {
          return {
            id: result.id,
            text: `${result.patient_name}（カルテ番号: ${result.medical_record_number}）`,
            ...result,
          };
        });

        return {
          results: results,
          pagination: {
            more: data.more
          }
        };
      },
    },
    allowClear: true,
    dropdownAutoWidth: true,
    language: "ja",
    minimumInputLength: 1,
    placeholder: "カルテを「カルテ番号」で検索",
    templateResult: medicalRecordFormat,
  })
})

function patientFormat(res) {
  if (res.loading) {
    return res.text;
  }

  var $container = $(`
    <div class="flex items-center space-x-4">
      <img src="${res.photo}" class="w-[60px] h-[60px] object-cover">
      <div class="grow min-w-0">
        <p class="font-bold">${res.name}</p>
        <p class="text-xs text-gray-500">カルテ番号: ${res.medical_record_number} / 性別: ${res.sex} / 誕生日: ${res.birth_date}</p>
      </div>
    </div>
  `);

  return $container;
}

$(document).ready(function () {
  $(".js-patient-select2").select2({
    ajax: {
      url: "/api/patients",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          q: params.term,
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        const results = data.results.map(result => {
          return {
            id: result.id,
            text: result.name,
            ...result,
          };
        });

        return {
          results: results,
          pagination: {
            more: data.more
          }
        };
      },
    },
    allowClear: true,
    dropdownAutoWidth: true,
    language: "ja",
    minimumInputLength: 1,
    placeholder: "患者を「カルテ番号」か「名前」で検索",
    templateResult: patientFormat,
  })
})

// メモ雛形

$(document).ready(function () {
  $(document).on("click", ".js-select-memo-template", function(){
    $(`#${$(this).data("target")}`).val($(this).data("body"))
    $("#js-memo-template-modal").remove()
  })
})

// 画像切り抜き

let croppedImageContainer;
let croppedImageField;

const setCropSetting = function(imageContainer, imageField) {
  croppedImageContainer = $(imageContainer)
  croppedImageField = $(imageField)
}

window.setCropSetting = setCropSetting

$(document).ready(function () {
  let cropper

  $(document).on("change", "#js-clipping-image-field", function(e){
    if (e.target.files[0] === undefined) {
      return false
    }

    const file = e.target.files[0]

    if (!file.type.startsWith('image/')) {
      alert(file.type)
      alert("画像ファイルをアップロードしてください。")

      return false
    }

    const blobUrl = window.URL.createObjectURL(file)
    const imgElement = $("#js-clipping-image")

    imgElement.attr("src", blobUrl)

    cropper = new Cropper(imgElement.get(0), {
      aspectRatio: 1 / 1,
      scalable: false,
      zoomable: false,
    })

    $("#js-clipping-step1").hide()
    $("#js-clipping-step2").fadeIn()
  })

  $(document).on("click", "#js-clip-button", function(){
    const croppedCanvas = cropper.getCroppedCanvas()
    croppedImageContainer.find("img").attr("src", croppedCanvas.toDataURL("image/jpeg"))
    croppedImageContainer.fadeIn()
    croppedImageField.val(croppedCanvas.toDataURL("image/jpeg"))
    $("#js-clipping-modal").remove()
  })
})

// 細胞発注カレンダー
$(document).ready(function () {
  Rails.fire($("#js-load-cell-order-calendar-form")[0], "submit")
})

// 細胞発注

$(document).ready(function () {
  $("#js-cell-order-medical-record-id-field").on("change", function(){
    $("#js-set-cell-order-medical-record-id-field").val($(this).val())
  })

  $("#set-cell-order-fields").on("click", function(){
    Rails.fire($("#js-set-cell-order-form")[0], "submit")
  })
})

// カルテ治療内容追加

const changeCategory = function(categoryId){
  $(".js-category").hide()
  $(`.js-category[data-id='${categoryId}']`).fadeIn()
  $(".js-change-category-button").removeClass("border-helene-dark-green")
  $(`.js-change-category-button[data-id='${categoryId}']`).addClass("border-helene-dark-green")
}

window.changeCategory = changeCategory

// カルテ治療内容未払い計算

const setUnpaid = function(index){
  let total = 0

  total += Number($(`.js-medical-record-treatment-price-field[data-index="${index}"]`).val())
  total += Number($(`.js-medical-record-treatment-price-adjustment-field[data-index="${index}"]`).val())
  $(`.js-medical-record-payment-price-field[data-index="${index}"]`).each(function(){
    total -= Number($(this).val())
  })

  if(isNaN(total)) {
    $(`.js-unpaid-field[data-index="${index}"]`).val("Error")
  } else {
    if(total < 0) {
      $(`.js-unpaid-field[data-index="${index}"]`).val(`${total}(過払い)`)
    } else {
      $(`.js-unpaid-field[data-index="${index}"]`).val(total)
    }
  }
}

const setAllUnpaid = function(index){
  $(".js-medical-record-treatment-price-field").each(function(){
    setUnpaid($(this).data("index"))
  })
}

window.setUnpaid = setUnpaid
window.setAllUnpaid = setAllUnpaid

$(document).ready(function () {
  $(document).on("keyup", ".js-set-unpaid-field-hook", function(){
    setUnpaid($(this).data("index"))
  })

  setAllUnpaid()
})

// 同時編集チェック

$(document).ready(function () {
  if($("#js-active-page-form").length > 0) {
    Rails.fire($("#js-active-page-form")[0], "submit")
    setInterval(function(){
      Rails.fire($("#js-active-page-form")[0], "submit")
    }, 5000);
  }
})

// OR検索

$(document).ready(function () {
  $(".js-or-search").on("keyup", function(){
    const element = this
    const templateElement = $(this).nextAll("input:disabled")
    $(`[name="${templateElement.attr("name")}"]:not([disabled])`).remove()

    $.each($(this).val().split(/ |　/), function(index, value){
      const cloneElement = templateElement.clone()
      cloneElement.val(value)
      cloneElement.prop("disabled", "")
      $(element).after(cloneElement)
    })
  })

  $(".js-or-search").keyup()
})

// タブ

$(document).ready(function () {
  $(document).on("click", ".js-tab", function(){
    $(".js-tab").removeAttr("data-active")
    $(this).attr("data-active", "")
    $(".js-tab-target").hide()
    $(`.js-tab-target[data-target="${ $(this).data("target") }"]`).fadeIn()
  })
})
